import React, { useState, useEffect } from 'react';
import '../css/Header.css';

import axios from "axios";
import useLocalStorage from 'react-use-localstorage';
// import use auth


function Header() {
    

 
    return (
        <div className="header">
            <div className="header_cont">
                <div className="name_logo_cont">
                    <img className="header_image"  alt="profile img" />
                    <p className="header_name_user">{}</p>
                </div>

                {/* code to display igc in header */}
                    <div>
                    <p className="header_name_user"></p>
                    </div>
                {/* code to display igc in header */}

                <div>
                <p className="header_name_user"></p>
                </div>

                <div>
                    <button className='login_btn'  >Logout</button>
                </div>
            </div>
        </div>
    )
}

export default Header
