import React from "react";
import TheBackgroundMusic from "./components/theBackgroundMusic";
import "./css/hamburger.css";
const App = ({ children }) => {
  return (
    <div className="" id="outer-container">
      <div className="App">{children}</div>
{/*       <TheBackgroundMusic /> */}
    </div>
  );
};

export default App;
