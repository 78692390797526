import { Link } from "react-router-dom";
import TheAuthHeader from "./TheAuthHeader";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as Ig } from "../images/instagram.svg";
import { ReactComponent as Fb } from "../images/facebook.svg";
import React, { useState } from "react";
import "../css/landingPage.css";

const API_URL = "https://api.majestic-arcade.com/contact-uses";

function LandingPage() {
  /*  const [name, setName] = useState(""); */
  let [email, setEmail] = useState("");
  /*   const [phone, setPhone] = useState(""); */
  let submitted = false;
  const textInput = React.useRef();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email,
          message: "Email from landing page",
        }),
      });
      submitted = true;
      textInput.current.value = "";
      setEmail("");

      alert("Form submitted");
    } else if (submitted === true) {
      alert("Already Submitted");
    } else {
      alert("Failed Submission");
    }
  };

  return (
    <div>
      <section className="about-page">
        <div className="landing-page-background">
          <h1>
            Majestic Arcade
            <br />
            Website is being updated</h1>
        </div>

      </section>
    </div>
  );
}

export default LandingPage;