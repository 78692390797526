import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

import App from "./App.test";
import "./css/style.css";
import Home from "./components/Home";

import LandingPage from "./components/landingPage";
import Privacy from "./components/privacy";

import About from "./components/about";
import Signup from "./components/signup";
import GameUpdates from "./components/gameUpdates";

//Game Import


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App>
        <Switch>
          <Route path="/games" component={LandingPage} />
          <Route path="/privacy" component={LandingPage} />
          <Route path="/about" component={LandingPage} />
          <Route path="/signup" component={LandingPage} />
          <Route path="/download-games" component={LandingPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </App>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
